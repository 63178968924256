import React, { type PropsWithChildren } from 'react';
import { SearchDialogWrapper } from './search-dialog.styled';

type SearchDialogProps = PropsWithChildren<{
	style?: React.CSSProperties;
	shouldFillContainer?: boolean;
	isExpanded?: boolean;
}>;

export const SearchDialog = ({
	children,
	style,
	shouldFillContainer,
	isExpanded,
}: SearchDialogProps) => (
	<SearchDialogWrapper
		shouldFillContainer={shouldFillContainer}
		isExpanded={isExpanded}
		data-test-id="search-dialog-dialog-wrapper"
		tabIndex={-1}
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		style={style}
	>
		{children}
	</SearchDialogWrapper>
);
