/* eslint-disable @repo/internal/react/no-class-components */
import React, { type ComponentType, type ErrorInfo, type PropsWithChildren } from 'react';

type ErrorBoundaryState = {
	error?: Error;
	errorInfo?: ErrorInfo;
};

type ErrorComponentProps = {
	error: Error;
	errorInfo: ErrorInfo;
};

type ErrorBoundaryProps = {
	fallback?: React.ReactNode;
	renderWithErrorInfo?: ComponentType<ErrorComponentProps>;
	onException: (error: Error) => void;
};

/**
 * Reusable error boundary for search components.
 *
 * As of React 16, there is no alternative to class components for error
 * boundaries.
 */
export class ErrorBoundary extends React.Component<
	PropsWithChildren<ErrorBoundaryProps>,
	ErrorBoundaryState
> {
	constructor(props: React.PropsWithChildren<ErrorBoundaryProps>) {
		super(props);

		this.state = { error: undefined, errorInfo: undefined };
	}

	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.props.onException(error);
		this.setState({ error, errorInfo });
	}

	render() {
		const { renderWithErrorInfo: RenderWithErrorInfo } = this.props;

		if (RenderWithErrorInfo && this.state.error && this.state.errorInfo) {
			return <RenderWithErrorInfo error={this.state.error} errorInfo={this.state.errorInfo} />;
		}

		if (this.state.error) {
			return this.props.fallback !== undefined ? this.props.fallback : null;
		}

		return this.props.children;
	}
}
